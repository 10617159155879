// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"

import "trix"
import "@rails/actiontext"

require("jquery")
require("easy-autocomplete")

import "../packs/assets"
import "../packs/contact_types"
import "packs/sort"
import "../packs/cash_pannel"

import "chartkick/chart.js"
import "chartkick"

import Sortable from "sortablejs";

window.Sortable = Sortable;
window.bootstrap = bootstrap;

Rails.start()
ActiveStorage.start()
Turbolinks.start()

import 'bootstrap-icons/font/bootstrap-icons.css'

import * as bootstrap from 'bootstrap'
import "../stylesheets/application"

import {initKanbanSortable} from "../plugins/initSortable";
import {initColumnSortable} from '../plugins/initColumnSortable';
import {initSortableFields} from '../plugins/initFieldsSortable';
import {initSortableTable} from '../plugins/initSortableTable';

document.addEventListener("turbolinks:visit", function () {
    Turbolinks.clearCache();
    let currentUserId = document.querySelector("body").getAttribute("data-current-user-id");
    if (currentUserId) {
        subscribeToProgressChannel(currentUserId);
    }
});

document.addEventListener('turbolinks:load', fetchCashPannel);
document.addEventListener('turbolinks:load', fetchHistoricCash);

function showOverlay() {
    $('#overlay').fadeIn();
    const feedbackElement = $('#process-feedback');
    feedbackElement.html("");
}

function hideOverlay() {
    const overlay = $('#overlay');
    const feedbackElement = $('#process-feedback');
    overlay.fadeOut();
    // Add a delay before hiding the overlay (e.g., 1000ms = 1 second)
    setTimeout(function () {
        feedbackElement.addClass('d-none');
        feedbackElement.html("");
    }, 1000); // You can adjust the delay time according to your preference
}

document.addEventListener("turbolinks:visit", function () {
    showOverlay();
});

document.addEventListener("turbolinks:load", function () {
    hideOverlay();
});

$(document).ready(function () {
    hideOverlay();
});

document.addEventListener('turbolinks:load', function () {

    // Show the correct tab
    var hash = location.hash.replace(/^#/, '');
    if (hash) {
        $('.nav-pills a[href="#' + hash + '"]').tab('show');
    }

    $('.nav-pills a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    })


    const kanbanUls = document.querySelectorAll(".kanban .kanban-col-content");
    if (kanbanUls) {
        initKanbanSortable(kanbanUls);
    }

    const kanbanCols = document.querySelector('.kanban-cols');
    if (kanbanCols) {
        initColumnSortable();
    }

    const contactTypes = document.querySelector('#sortable-fields');
    if (contactTypes) {
        initSortableFields();
    }

    const tableSortableThs = document.querySelector('.sortable-ths');
    if (tableSortableThs) {
        initSortableTable();
    }

    var toastElList = [].slice.call(document.querySelectorAll('.toast'))
    var toastList = toastElList.map(function (toastEl) {
        return new bootstrap.Toast(toastEl).show()
    })

    $(".colored-number").each(function () {
        if ($(this).text().replace("'", "") < 0) {
            $(this).css("color", "red")
        }
    });
    $(".colored-number").each(function () {
        if ($(this).text().replace("'", "") > 0) {
            $(this).css("color", "green")
        }
    });


    activateTooltips();

    function activateTooltips() {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
        })
    }

    searchForm();

    function searchForm() {
        let $input;
        $input = $('*[data-behavior="autocomplete-navbar"]');

        var options = {
            url: function (phrase) {
                return "/search.json?q=" + phrase;
            },
            adjustWidth: false,
            getValue: "name",
            theme: $('body').data('theme'),
            template: {
                type: "custom",
                method: function (value, item) {
                    return value + "<i class='text-muted'> | " + item.type + "</i>";
                }
            },
            list: {
                maxNumberOfElements: 20,
                onChooseEvent: function () {
                    let selected = $input.getSelectedItemData();
                    Turbolinks.visit(selected.link);
                    $('#searchModal').modal('hide');
                    $('#overlay').fadeIn();
                }
            }
        };

        $input.easyAutocomplete(options);

        $('.search-form').on('keypress', e => {
            if (e.keyCode == 13) {
                return false;
            }
        });
    }
});

